import React, { useRef } from 'react'
import { shaderMaterial} from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'
import { EffectComposer, Noise } from '@react-three/postprocessing'
import { BlendFunction } from "postprocessing";
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { Leva } from 'leva'
// import { Perf } from 'r3f-perf'

import Mouse from './Mouse'

import { useControls } from 'leva'

const NoiseShaderMaterial = shaderMaterial(
  // Uniforms
  {
    uTime: 0.0,
    uColor: new THREE.Color(0xffdfb0),
  },
  // Vertex Shader
  glsl`
  precision mediump float;
    varying vec2 vUv;
    varying vec3 vPosition;
    varying vec3 vNoisePos;
    uniform float uTime;

      void main() {
        vUv = uv;
        vPosition = position;

        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
    `,
  // Fragment Shader
  glsl`
    #pragma glslify: snoise3 = require(glsl-noise/simplex/3d);
    precision mediump float;
    varying vec3 vPosition;
    varying vec2 vUv;
    uniform float uTime;
    uniform vec3 uColor;

    void main() {
      vec3 pos = vec3(vUv, 1.0);
      float noiseFreq = 0.33;
      float noiseAmp = 0.9;
      vec3 noisePos = vec3(pos.x * noiseFreq + (uTime * 0.33), pos.y, pos.z);
      pos.x += snoise3(noisePos);

      gl_FragColor = vec4(pos * uColor, 1);
    }
  `
)

extend({NoiseShaderMaterial});

function Plane() {
  const { viewport } = useThree();
    const shaderMaterial = useRef();

    const { color } = useControls('Color', 
      { color: '#ffdfb0' }
    )

    useFrame((state) => {
      const { clock } = state;
      shaderMaterial.current.uTime = clock.getElapsedTime();
    });

    return (
        <mesh scale={1}>
            <planeGeometry args={[ viewport.width, viewport.height,1,1]} />
            <noiseShaderMaterial uColor={color} ref={shaderMaterial} />
        </mesh>
    )
}

function Background() {
    const { opacity } = useControls('Noise', 
      { opacity:  0.5,
        premultiply: true,
      }
    )

  return (
    <div className="background">
      <Leva hidden={true} />
      <Canvas style={{ height: '100vh'}}>
      {/* <Perf position="bottom-left"/> */}
        {/* <OrbitControls /> */}
        <ambientLight intensity={10} />
        <Plane />
        <Mouse />
        <EffectComposer>
          <Noise opacity={opacity} blendFunction={BlendFunction.ADD}/>
        </EffectComposer>
      </Canvas>
    </div>
  )
}

export default Background