import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'));
const preloader = document.querySelector('.preloader');
// if you want to show the loader when React loads data again
const showLoader = () => preloader.classList.remove('preloader--hide');
const hideLoader = () => preloader.classList.add('preloader--hide');

root.render(
    <BrowserRouter>
    <App hideLoader={hideLoader} showLoader={showLoader} />
    </BrowserRouter>
);