import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="site-footer">
        <div className="container">
        <nav>
            <ul>
                <li>
                    <Link to="/impressum">Impressum</Link>
                </li>
            </ul>
        </nav>
        </div>
    </footer>
  )
}

export default Footer