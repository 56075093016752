import { NavLink } from "react-router-dom";
import { useSpring, a } from "@react-spring/web";

import portrait from "../assets/images/portrait.jpg";
import wordCloud from "../assets/images/wordcloud.svg";

import seido2 from "../assets/images/seido2.jpg";
import ancho from "../assets/images/ancho1.jpg";
import limerence3 from "../assets/images/limerence3.jpg";

function Home() {
  const contentSpring = useSpring({
    from: { opacity: 0, y: "100vh" },
    to: { opacity: 1, y: "0vh" },
  });

  const portraitSpring = useSpring({
    from: { opacity: 0, y: "100vh" },
    to: { opacity: 1, y: "0vh" },
    delay: 175,
  });

  return (
    <main className="site-main home-page" id="home">
      <section id="about">
        <div className="container">
          <a.div className="row" style={contentSpring}>
            <h2>hello.</h2>
            <p>
              Hey, mein Name ist Sonja Klaus und ich bin Mediengestalterin mit
              Leidenschaft für visuelle Kommunikation. Mein Schwerpunkt liegt in
              der Gestaltung von Print- und Online-Medien wie Flyer, Plakate,
              Logoentwicklungen und Social-Media-Inhalten. Ich verfüge über ein
              gutes Auge für Design, ein ausgeprägtes Verständnis für Typografie
              und eine hohe Affinität zu neuen Technologien. Mit meiner
              Kreativität und meinem technischen Know-how bringe ich Projekte
              erfolgreich zum Abschluss und sorge dafür, dass die visuelle
              Identität meiner Kunden optimal zur Geltung kommt.
            </p>
          </a.div>
          <a.div className="row" style={portraitSpring}>
            <img src={portrait} alt="" />
          </a.div>
        </div>
      </section>
      <section id="skills">
        <div className="container">
          <h2 className="section-title">Talents & skills</h2>
          <div className="vertical-line"></div>
          <img src={wordCloud} alt="" />
          <div className="vertical-line"></div>
          <p>
            Ich bin eine motivierte und aufgeschlossene Person mit einer breiten
            Palette an Fähigkeiten. Im Team arbeite ich effektiv und trage zur
            positiven Atmosphäre bei. In der digitalen Welt bin ich sicher und
            nutze effiziente Shortcuts, um Aufgaben schnell und präzise zu
            erledigen. Ich verfüge über eine starke Kreativität, die ich in
            Projekten einbringen kann. Mit meiner Begeisterung und Energie
            motiviere ich andere und treibe Projekte voran.
          </p>
        </div>
      </section>
      <section id="karriere">
        <div className="container">
          <h2 className="section-title">Career</h2>
          <div className="content">
            <div className="row">
              <div className="col">
                <p>2023 - Now</p>
              </div>
              <div className="col">
                <p>
                  Werksfarbe GmbH - Frankfurt am Main
                  <br />
                  Junior Art Director
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p>2020 - 2023</p>
              </div>
              <div className="col">
                <p>
                  GOLDfisch ART GmbH - Marburg
                  <br />
                  Grafik- & Mediengestalterin Digital und Print
                  <br />
                  Schwerpunkt: Kommunikation und Visualisierung
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="portfolio">
        <div className="fluid-container">
          <h2 className="section-title">Portfolio</h2>
          {/* <div className="row project-1">
                    <div className="col">
                        <img src={placeholder} alt="" />
                    </div>
                    <div className="col">
                        <div className="project-line"></div>
<div className="content">
<div className="project-category">Corporate Design</div>
                        <h3 className="project-title">Willow Garden</h3>
                        <p className="project-description">Garten- und Landschaftsbau</p>
                        <NavLink to="/project/willow-garden" className="project-link">Zum Projekt</NavLink>
                   
</div>
     </div>
                </div> */}
          <div className="row project-2">
            <div className="col">
              <img src={seido2} alt="" />
            </div>
            <div className="col">
              <div className="project-line"></div>
              <div className="content">
                <div className="project-category">Corporate Design</div>
                <h3 className="project-title">Seido Karate</h3>
                <p className="project-description">
                  Traditionelle japanische Kampfkunst
                </p>
                <NavLink to="/project/seido-karate" className="project-link">
                  Zum Projekt
                </NavLink>
              </div>
            </div>
          </div>
          <div className="row project-3">
            <div className="col">
              <img src={limerence3} alt="" />
            </div>
            <div className="col">
              <div className="project-line"></div>
              <div className="content">
                <div className="project-category">Package Design</div>
                <h3 className="project-title">Limerence</h3>
                <p className="project-description">Kosmetik</p>
                <NavLink to="/project/limerence" className="project-link">
                  Zum Projekt
                </NavLink>
              </div>
            </div>
          </div>
          <div className="row project-4">
            <div className="col">
              <img src={ancho} alt="" />
            </div>
            <div className="col">
              <div className="project-line"></div>
              <div className="content">
                <div className="project-category">Editorial</div>
                <h3 className="project-title">Ancho Steakhouse</h3>
                <p className="project-description">
                  Restaurant mit Schwerpunkt auf Angus-Rind.
                </p>
                <NavLink
                  to="/project/ancho-steakhouse"
                  className="project-link"
                >
                  Zum Projekt
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faq">
        <div className="container">
          <div className="vertical-line"></div>
          <h2 className="faq-title">
            frequently
            <br />
            asked
            <br />
            questions
          </h2>
          <div className="vertical-line"></div>
          <div className="row">
            <p className="question">Drei Dinge von deiner Bucket-List?</p>
            <p className="answer">
              Das Ghibli Museum in Japan besuchen <br />
              Alle Shortcuts der Adobe-Cloud zu kennen <br />
              Einen perfekten Espresso aus der Siebträgermaschine zu brühen.
            </p>
          </div>
          <div className="row">
            <p className="question">Welche Trends siehst Du gerade?</p>
            <p className="answer">
              The 90’s are Back! Bunte, schrille Farben und Schriftarten, die
              förmlich nach Groove rufen und natürlich die KI generierten Fotos.
            </p>
          </div>
          <div className="row">
            <p className="question">Dein optimaler Ausgleich zum Job?</p>
            <p className="answer">
              League of Legends spielen, mich mit meinen Katzen beschäftigen und
              neue kulinarische Welten entdecken.
            </p>
          </div>
          <div className="row">
            <p className="question">
              Was hat dich inspiriert, diesen Beruf zu erlernen?
            </p>
            <p className="answer">
              Mit Paint hat es angefangen, heute ist es Illustrator.
            </p>
          </div>
          <div className="row">
            <p className="question">Tee oder Kaffee?</p>
            <p className="answer">Beides!</p>
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container">
          <h2 className="section-title">Contact</h2>
          <div className="row">
            <div className="col">
              <h3>Telefon</h3>
              <a href="01621857596">0162 18 57 596</a>
            </div>
            <div className="col">
              <h3>E-Mail</h3>
              <a href="mailto:info@sonjaklaus.digital ">
                info@sonjaklaus.digital
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Home;
