import { useParams } from "react-router-dom";
import { useSpring, a } from "@react-spring/web";
import { HashLink } from "react-router-hash-link";

import seido1 from "../assets/images/seido1.jpg";
import seido2 from "../assets/images/seido2.jpg";
import seido3 from "../assets/images/seido3.jpg";
import seido4 from "../assets/images/seido4.jpg";

import willow1 from "../assets/images/seido1.jpg";
import willow2 from "../assets/images/seido2.jpg";
import willow3 from "../assets/images/seido3.jpg";
import willow4 from "../assets/images/seido4.jpg";

import ancho1 from "../assets/images/ancho1.jpg";
import ancho2 from "../assets/images/ancho2.jpg";
import ancho3 from "../assets/images/ancho3.jpg";

import limerence1 from "../assets/images/limerence1.jpg";
import limerence2 from "../assets/images/limerence2.jpg";
import limerence3 from "../assets/images/limerence3.jpg";
import limerence4 from "../assets/images/limerence4.jpg";
import limerence5 from "../assets/images/limerence5.jpg";
import limerence6 from "../assets/images/limerence6.jpg";
import limerence7 from "../assets/images/limerence7.jpg";

function Gallery({ images }) {
  const gallery = images.map((image, key) => {
    return <img src={image} key={key} alt="" />;
  });

  return gallery;
}

function Content() {
  const { slug } = useParams();

  const projectSpring = useSpring({
    from: { opacity: 0, y: "100vh" },
    to: { opacity: 1, y: "0vh" },
  });

  switch (slug) {
    case "seido-karate":
      return (
        <>
          <a.section className="project-about" style={projectSpring}>
            <div className="container">
              <div className="project-category">Corporate Design</div>
              <h1 className="project-title">Seido Karate</h1>
              <div className="vertical-line"></div>
              <div className="section-title">Briefing</div>
              <p className="project-description">
                “Unser Karate-Training ist für Kinder und Erwachsene zwischen 4
                und 78 Jahren geeignet und berücksichtigt ihren Fitnessgrad und
                körperliche Einschränkungen. Für Kinder und Jugendliche bieten
                wir ein spezielles Training an, das ihre Stärken und Schwächen
                berücksichtigt. " Zusätzlich bieten wir “Fight-Aerobic” an, zum
                Abnehmen oder zum Gesünder leben.”
              </p>
              <p className="project-description">
                Das schon vorhandene Logo soll ein Redesign geben. Dabei ist zu
                achten, dass das Logo nicht wie die klassischen Kampfsport-Logos
                aussehen soll, da wir auch kleine Kinder und ältere mit
                ansprechen wollen. Die Farben Rot sollten im Logo vorhanden
                sein. In dem Logo soll zusätzlich der Kämpfer dargestellt
                werden.
              </p>
            </div>
          </a.section>
          <section className="project-impressions">
            <div className="container">
              <Gallery images={[seido1, seido2, seido3, seido4]} />
            </div>
          </section>
        </>
      );
    case "willow-garden":
      return (
        <>
          <a.section className="project-about" style={projectSpring}>
            <div className="container">
              <div className="project-category">Corporate Design</div>
              <h1 className="project-title">Willow Garden</h1>
              <div className="vertical-line"></div>
              <div className="section-title">Briefing</div>
              <p className="project-description">
                Willow Garden Garten- und Landschaftsbau bietet seinen Kunden
                eine breite sparte an Dienstleistungen an. Unter anderem die
                Pflege und Instandhaltung von Außenanlagen, Private Gärten und
                Landschaften. Das Unternehmen bietet zusätzlich noch
                Baggerarbeiten, Pool- und Teichbau sowie Pflasterarbeiten und
                Upcycling an.
              </p>
              <p className="project-description">
                Das Logo sollte edel, aber nicht hochpreisig wirken. Die
                Farbgebung wird in einem Grün stattfinden und die Natur soll im
                Vordergrund stehen. Der Name Willow Garden ist abgeleitet von
                dem Nachnamen und spiegelt sich als abstrakt dargestellte Form
                einer Weide im Logo wieder.
              </p>
            </div>
          </a.section>
          <section className="project-impressions">
            <div className="container">
              <Gallery images={[willow1, willow2, willow3, willow4]} />
            </div>
          </section>
        </>
      );
      case "limerence":
      return (
        <>
          <a.section className="project-about" style={projectSpring}>
            <div className="container">
              <div className="project-category">Packagedesign</div>
              <h1 className="project-title">Limerence</h1>
              <div className="vertical-line"></div>
              <div className="section-title">Briefing</div>
              <p className="project-description">
                Limerence ist eine aufstrebende Kosmetikfirma, die sich auf die
                obere Mittelschicht spezialisiert hat. Mit ihren luxuriösen
                Produkten möchte die Marke nicht nur das Äußere ihrer Kunden
                verbessern, sondern auch positive Emotionen wecken. Inspiriert
                von dem ersten Gefühl der Verliebtheit und den schönsten
                Erinnerungen, bietet Limerence ein unvergessliches
                Beauty-Erlebnis. Die Produktpalette von Limerence umfasst
                hochwertige Hautpflege- und Make-up-Artikel, die mit natürlichen
                Inhaltsstoffen und modernster Technologie entwickelt werden. Die
                Marke legt großen Wert auf Nachhaltigkeit und verwendet
                ausschließlich recycelbare Verpackungen und verzichtet auf
                Tierversuche. 
              </p>
            </div>
          </a.section>
          <section className="project-impressions">
            <div className="container">
              <Gallery
                images={[
                  limerence1,
                  limerence2,
                  limerence3,
                  limerence4,
                  limerence5,
                  limerence6,
                  limerence7,
                ]}
              />
            </div>
          </section>
        </>
      );
    case "ancho-steakhouse":
      return (
        <>
          <a.section className="project-about" style={projectSpring}>
            <div className="container">
              <div className="project-category">Editorial</div>
              <h1 className="project-title">Ancho Steakhouse</h1>
              <div className="vertical-line"></div>
              <div className="section-title">Briefing</div>
              <p className="project-description">
                Unser Schwerpunkt liegt bei Südamerikanischen Steaks vom
                "Angus-Rind". Schonend gebraten auf dem Lavastein - Grill mit
                unverwechselbarem Geschmack und Qualität aus reiner
                Weidenzucht..
              </p>
              <p className="project-description"></p>
            </div>
          </a.section>
          <section className="project-impressions">
            <div className="container">
              <Gallery images={[ancho1, ancho2, ancho3]} />
            </div>
          </section>
        </>
      );
      default:
      break;
  }
}

function Project() {
  return (
    <main className="site-main project-page">
      <Content />
      <div className="container">
        <HashLink className="back-link" to="/#portfolio">
          Back to portfolio
        </HashLink>
      </div>
    </main>
  );
}

export default Project;
