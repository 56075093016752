import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'

function NavigationLinks({className, handleClick}){
    return (
            <ul className={className}>
                <li>
                    <HashLink to="/#home" onClick={handleClick}>
                        <span data-content="About">About</span>
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/#skills" onClick={handleClick}>
                        <span data-content="Talents & Skills">
                            Talents & skills
                        </span>
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/#karriere" onClick={handleClick}>
                        <span data-content="Career">Career</span>
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/#faq" onClick={handleClick}>
                        <span data-content="FAQ">FAQ</span>
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/#contact" onClick={handleClick}>
                        <span data-content="Contact">Contact</span>
                    </HashLink>
                </li>
            </ul>
    )
}

function Navigation() {
    const [active, setActive] = useState(false);
    return (
        <>
        <nav>
            <div className={`burger ${active ? 'burger--active' : ''}`} onClick={() => setActive(!active)}>
                <span></span>
                <span></span>
            </div>
            <NavigationLinks className="nav-links nav-links--main" />
        </nav>
        <nav className={`overlay-nav ${active ? 'overlay-nav--active' : ''}`}>
            <NavigationLinks className="nav-links nav-links--overlay" handleClick={() => setActive(false)}/>
        </nav>
        </>
    )
}

export default Navigation;
