function ErrorPage() {
  return (
    <section id="error">
        <div className="container">
            <h1>
                <span>404</span><br />
                Page not found.
            </h1>
        </div>
    </section>
  )
}

export default ErrorPage