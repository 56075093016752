import { Routes, Route, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

import './styles/main.scss'

import Background from './components/Background'

import Header from './components/Header'
import Footer from './components/Footer'
import Home from './pages/Home'
import Project from './pages/Project'
import Page from './pages/Page'

function App({ hideLoader }) {
  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <>
      <Background />
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/:slug" element={<Project />} />
        <Route path="/:slug" element={<Page />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
